import { PropertyTranslation } from '@/models/PropertyTranslation'

/* eslint-disable camelcase */
export class Property {
  id: string;
  translations: PropertyTranslation[]
  full_code: string
  meta: any
  user_id: string
  status: string
  area_id: string
  category: any
  code: any
  area: any
  media: any
  user: any
  client: any
  location: any
  office_id: string
  auction: boolean
  auction_date: number | null
  views: number
  assignation_state: string | null;
  facade_length: number | null;
  date_created: number | null;
  date_updated: number | null;

  constructor(data: any = {}) {
    this.id = data.id
    this.translations = data.translations || []
    this.meta = data.meta || { diffs: [] }
    this.full_code = data.full_code || null
    this.status = data.status || null
    this.user_id = data.user_id || null
    this.office_id = data.office_id || null
    this.auction = data.auction || false
    this.auction_date = data.auction_date || null
    this.area_id = data.area_id || null
    this.area = data.area || null
    this.user = data.user || null
    this.client = data.client || null
    this.category = data.category || null
    this.views = data.views || 0
    this.code = data.code || null
    this.facade_length = data.facade_length || null
    this.assignation_state = data.assignation_state || null
    this.location = data.location || { type: 'Point', coordinates: [] }
    this.media = data.media || { images: [], images360: [], videos: [] }
    this.date_created = data.date_created || null
    this.date_updated = data.date_updated || null
  }
}
