





































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import { errorMsg } from '@/utils'
import { mediaTypes } from '@/utils/property'
import { UserModule } from '@/store/modules/user'
@Component({
  name: 'FileGallery',
  components: {
    Draggable
  }
})

export default class extends Vue {
  @Prop({ required: true }) private files!: any
  @Prop({ required: true }) private entityId!: any
  @Prop({ required: true }) private type!: string
  @Prop({ required: false }) private suffix!: string
  private mediaTypes = mediaTypes
  private description = ''

  private getFile(file: any) {
    return `/static/${this.type}/${this.entityId}/files/${file.name}`
  }

  private createImageObject(event: any) {
    return {
      name: event.name.replaceAll(' ', '_'),
      src: URL.createObjectURL(event.raw),
      raw: event.raw,
      types: '',
      isNew: true,
    }
  }

  private fileExists(event: any) {
    const name = event.name && event.name.replaceAll(' ', '_')
    return this.files.some((file: any) => file.name === name)
  }

  private validateImageType(event: any) {
    return true
  }

  private handleOnChange(event: any) {
    if (!this.validateImageType(event)) {
      return errorMsg('propertyDetail.invalidFileType')
    }
    if (this.fileExists(event)) {
      return errorMsg('propertyDetail.fileExists')
    }
    this.files.push(this.createImageObject(event))
  }


  private downloadFile(file: any) {
    const link = document.createElement('a')
    link.href = this.getFile(file)
    link.setAttribute('download', file.name)
    document.body.appendChild(link)
    link.click()
  }

  copyLink(file: any) {
    navigator.clipboard.writeText( UserModule.baseUrl+this.getFile(file));
  }
  private removeFromGallery(index: number) {
    if (index >= 0) {
      this.files.splice(index, 1)
    }
  }
}
