import { getLocale } from '@/lang'
/* eslint-disable camelcase */
export class PropertyTranslation {
  id: string | null;
  locale: string;
  title: string;
  description: string;
  portal: string;
  address: string;
  public_address: string;

  constructor(params: any = {}) {
    this.id = params.id || null
    this.locale = params.locale || getLocale()
    this.title = params.title || ''
    this.description = params.description || ''
    this.portal = params.portal || ''
    this.address = params.address || ''
    this.public_address = params.public_address || ''
  }
}
