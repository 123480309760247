










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'MediaAlert'
})

export default class extends Vue {
  @Prop({ required: true }) private diffs!: any
  @Prop({ required: true }) private keys!: string
  @Prop({ required: false }) private status!: string

  get showAlert() {
    const diffs = this.diffs || []
    return this.status === 'sent_for_approval' && diffs.some((diff: string) => {
      return this.keys.includes(diff)
    })
  }
}
