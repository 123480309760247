
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Tinymce from '@/components/Tinymce/index.vue'
import { translate } from '@/api/translator'
const LANGUAGE_MAPPING: Record<string, string> = {
  al: 'sq',
  se: 'sv'
}
@Component({
  name: 'Comments',
  components: {
    Tinymce
  }
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any
  @Prop({ required: true }) private translations!: any
  @Prop({ required: true }) private language!: string

  @Watch('language')
  private onLanguageChange(locale: string) {
    this.translations[locale].description = this.translations[locale].description || ''
    this.translations[locale].portal = this.translations[locale].portal || ''
  }

  get sourceLang() {
    return this.translations.en.description ? 'en' : 'el'
  }

  get sourceDescription() {
    return this.translations[this.sourceLang].description
  }

  get targetLang() {
    return LANGUAGE_MAPPING[this.language] || this.language
  }

  private async requestTranslation() {
    try {
      const { data } = await translate({
        source: this.sourceLang,
        target: this.targetLang,
        text: this.sourceDescription
      })
      this.translations[this.language].description = data
    } catch (error) {
      console.error('Failed to request translation:', error)
    }
  }

  private openGoogleTranslate(): void {
    this.navigateToGoogleTranslate({
      sourceLang: this.sourceLang,
      targetLang: this.targetLang,
      text: this.sourceDescription
    })
  }

  private generateGoogleTranslateURL({ sourceLang, targetLang, text }: { sourceLang: string, targetLang: string, text: string }): string {
    const baseURL = 'https://translate.google.com/'
    const queryParams = new URLSearchParams({
      sl: sourceLang,
      tl: targetLang,
      text
    })
    return `${baseURL}?${queryParams.toString()}`
  }

  private navigateToGoogleTranslate({ sourceLang, targetLang, text }: { sourceLang: string, targetLang: string, text: string }): void {
    const url = this.generateGoogleTranslateURL({
      sourceLang,
      targetLang,
      text
    })
    window.open(url, '_blank')
  }
}
