






















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { slopeOptions } from '@/utils/property'
import SelectField from '@/views/property/components/SelectField.vue'

@Component({
  name: 'LandTechnicalFeatures',
  components: {
    SelectField
  }
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any

  private selectFields = {
    slope: {
      model: 'slope',
      labelKey: 'slope',
      optionsKey: 'slope',
      items: slopeOptions
    }
  }
}
