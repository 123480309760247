





































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import SelectField from '@/views/property/components/SelectField.vue'
import {
  electricityTypes,
  floorTypes,
  framingOptions,
  slopeOptions
} from '@/utils/property'
import { showAttribute } from '@/utils'

@Component({
  name: 'Comments',
  components: {
    SelectField
  }
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any

  private showAttribute = showAttribute

  private selectFields = {
    slope: {
      model: 'slope',
      labelKey: 'slope',
      optionsKey: 'slope',
      items: slopeOptions
    },
    floor_type: {
      model: 'floor_type',
      labelKey: 'floorType',
      optionsKey: 'floor_type',
      items: floorTypes
    },
    framing: {
      model: 'framing',
      labelKey: 'framing',
      optionsKey: 'framing',
      items: framingOptions
    },
    electricity_type: {
      model: 'electricity_type',
      labelKey: 'electricity_type',
      optionsKey: 'electricity_type',
      items: electricityTypes
    }
  }
}
