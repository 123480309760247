











































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'MoreAttributes'
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any

  private secondaryChecks = [
    'contract_copy',
    'building_permit_copy',
    'topographic',
    'coverage_plan',
    'floor_plan_copy',
    'ground_floor_plan_copy',
    'basement_floor_plan_copy',
    'land_registry_copy',
    'pea_copy',
    'electricity_provider_copy',
    'water_provider_copy',
    'iasve_copy',
    'weight_copy',
    'lease_copy',
    'joint_ownership',
    'keys',
    'banner'
  ]
}
