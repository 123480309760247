











































































































import MarkerMap from '@/components/GoogleMaps/marker-map.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { getTranslations } from '@/utils'
import { getRemoteAreas } from '@/api/areas'
import { UserModule } from '@/store/modules/user'
import { FilterOperator, FilterType } from '@/utils/filter'

@Component({
  name: 'LocationInformation',
  components: {
    MarkerMap
  }
})

export default class extends Vue {
  @Prop({ default: '600px' }) private height!: string
  @Prop({ required: true }) private property!: any
  @Prop({ required: true }) private translations!: any
  @Prop({ required: true }) private language!: string

  private getTranslations = getTranslations
  private areas: any = []
  private areasLoading = false

  get center() {
    return UserModule.officeCoords || null
  }

  created() {
    this.initializePropertyLocation()
    if (this.property.area) {
      this.setPlotAddressIfNotPresent()
      this.areas.push(this.property.area)
    }
  }

  private initializePropertyLocation() {
    if (!this.property.location) {
      this.$set(this.property, 'location', {
        type: 'Point',
        coordinates: []
      })
    }
  }

  private setPlotAddressIfNotPresent() {
    try {
      if (!this.property.meta.address_on_plot) {
        const { title, description } = this.property.area.translations.find((trans: any) => trans.locale === 'el')
        this.$set(this.property.meta, 'address_on_plot', `${title} (${description})`)
      }
    } catch (e) {}
  }

  private onLocationChange(coordinates: any) {
    this.$set(this.property.location, 'coordinates', coordinates)
  }

  private onAreaChange(value: any) {
    try {
      const address = this.getAddressFromAreaId(value)
      this.$set(this.property.meta, 'address_on_plot', address)
    } catch (e) {}
  }

  private getAddressFromAreaId(areaId: any) {
    const { title, description } = this.areas.find((area: any) => area.id === areaId)
    return `${title} (${description})`
  }

  private async getRemoteAreasList(query: string) {
    this.areasLoading = true
    this.areas = await getRemoteAreas(query, [{
      type: FilterType.field,
      key: 'level',
      value: 1,
      operator: FilterOperator.gt
    }])
    this.areasLoading = false
  }
}
