








import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Loader } from '@googlemaps/js-api-loader'
import { extractLatLongFromLocation } from '@/utils'
// eslint-disable-next-line no-undef
import MapOptions = google.maps.MapOptions;

@Component({
  name: 'GoogleMarkerMap'
})

export default class extends Vue {
  @Prop({ default: '600px' }) private height!: string
  @Prop({ default: '100%' }) private width!: string
  @Prop({ required: false }) private location!: any
  @Prop({ required: false }) private center!: any

  private marker: any
  private map: any
  private google: any

  get coordinates() {
    return extractLatLongFromLocation(this.location)
  }

  get mapConfig() {
    return {
      zoom: 12,
      center: this.coordinates || this.center || {
        lat: 39.0742,
        lng: 21.8243
      }
    }
  }

  mounted() {
    new Loader({
      //apiKey: 'AIzaSyDOz1rn_PcMcurA_ZrGbvqWPoQEBO_Vov0',
      apiKey: 'AIzaSyDRZet_yXdSPW1Dk4PWfNFv_uJTy0a7nHk',
      version: 'weekly',
      channel: '2'
    }).load().then((google) => {
      this.google = google
      this.map = new this.google.maps.Map(
        this.$refs.googleMap as HTMLElement,
        this.mapConfig as MapOptions
      )
      this.marker = this.addAndGetMarker()
      this.google.maps.event.addListener(
        this.map, 'click', this.onMapClick
      )
    })
  }

  private onMapClick(event: any) {
    this.$emit('location-changed', [
      event.latLng.lat(),
      event.latLng.lng()
    ])
  }

  private addAndGetMarker() {
    if (!this.coordinates) {
      return null
    }
    return new this.google.maps.Marker({
      position: this.coordinates,
      map: this.map
    })
  }

  @Watch('location.coordinates')
  private onLocationChange() {
    if (!this.google) {
      return
    }
    this.marker && this.marker.setMap(null)
    this.marker = this.addAndGetMarker()
  }
}
