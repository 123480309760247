





































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import SelectField from '@/views/property/components/SelectField.vue'
import { states } from '@/utils/property'
import { showAttribute } from '@/utils'

@Component({
  name: 'Construction',
  components: {
    SelectField
  }
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any

  private showAttribute = showAttribute

  private propertyState = {
    model: 'state',
    labelKey: 'state',
    optionsKey: 'property_state',
    items: states
  }

  get constructionYear() {
    return this.property.construction_year
      ? new Date(this.property.construction_year.toString())
      : null
  }

  set constructionYear(value: any) {
    this.$set(
      this.property,
      'construction_year',
      value ? new Date(value).getFullYear() : null
    )
  }

  get renovationYear() {
    return this.property.renovation_year
      ? new Date(this.property.renovation_year.toString())
      : null
  }

  set renovationYear(value: any) {
    this.$set(
      this.property,
      'renovation_year',
      value ? new Date(value).getFullYear() : null
    )
  }
}
