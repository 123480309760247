






















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'SelectField'
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any
  @Prop({ required: true }) private field!: any
}
