




import { Component, Vue } from 'vue-property-decorator'
import PropertyDetail from './components/PropertyDetail.vue'

@Component({
  name: 'EditProperty',
  components: {
    PropertyDetail
  }
})
export default class extends Vue {}
